import React from "react";
import { Flex, Text, Container, Box, ContentWrapper, Icon, Image } from "ui/atoms";
import { Page, Button } from "ui/molecules";
import { COLORS } from "config/colors";
import Icons from "assets/icons";
import { connect } from "react-redux";
import { changeTheme } from "redux/actions/theme";
import { changeAuditModalOpened } from "redux/actions/requestAuditModal";
import useWindowDimensions from "hooks/useWindowDimensions";
import { WINDOW_SIZES } from "config/dimensions";
import { Popup } from "react-typeform-embed";
// import useWindowDimensions from "hooks/useWindowDimensions";
// import { WINDOW_SIZES } from "config/dimensions";
// import contractData from "assets/json/contracts.json";

const Company = (props: any) => {
  const { deviceWidth } = useWindowDimensions();
  return (
    <Page>
      <Box padding={42} backgroundColor={COLORS.BLACK_THEME_BLACK} />
      <ContentWrapper backgroundColor={COLORS.BLACK_THEME_BLACK}>
        <Container>
          <Box padding={deviceWidth > WINDOW_SIZES.SIZE_874 ? 50 : 20}></Box>
          <Box paddingHorizontal={20} paddingVertical={15}>
            <Flex flexDirection="column" alignItems="center" gap={20}>
              <Flex
                flexDirection={
                  deviceWidth > WINDOW_SIZES.SIZE_874 ? "row" : "column"
                }
                alignItems="center"
                gap={30}
              >
                <Box
                  padding={deviceWidth > WINDOW_SIZES.SIZE_1600 ? 0 : 20}
                ></Box>
                <Box maxWidth="100%">
                  <Icon icon={Icons.profits} size="EXTRA_SUPER_LARGE" />
                </Box>
                <Box maxWidth="100%">
                  <Text
                    type="title"
                    lineHeight={1.5}
                    fontWeight={700}
                    center={deviceWidth > WINDOW_SIZES.SIZE_874 ? false : true}
                  >
                    The Problem We Solve
                  </Text>
                  <Box padding={5}></Box>
                  <Text
                    type="smalltitle"
                    lineHeight={1.5}
                    fontWeight={600}
                    color={COLORS.BLACK_THEME_WHITE_GREEN}
                    center={deviceWidth > WINDOW_SIZES.SIZE_874 ? false : true}
                  >
                    Promising teams in web3 struggle with raising the funds
                    needed for their growth.
                  </Text>
                </Box>
              </Flex>
              <Box padding={30}></Box>
              <Flex
                flexDirection={
                  deviceWidth > WINDOW_SIZES.SIZE_874 ? "row" : "column"
                }
                alignItems="center"
                gap={30}
              >
                <Box
                  padding={deviceWidth > WINDOW_SIZES.SIZE_1024 ? 25 : 0}
                ></Box>
                <Box maxWidth="100%">
                  <Icon icon={Icons.fidelity} size="EXTRA_SUPER_LARGE" />
                </Box>
                <Box maxWidth="100%">
                  <Text
                    type="title"
                    lineHeight={1.5}
                    fontWeight={700}
                    center={deviceWidth > WINDOW_SIZES.SIZE_874 ? false : true}
                  >
                    Our mission
                  </Text>
                  <Box padding={5}></Box>
                  <Text
                    type="smalltitle"
                    lineHeight={1.5}
                    fontWeight={600}
                    color={COLORS.BLACK_THEME_WHITE_GREEN}
                    center={deviceWidth > WINDOW_SIZES.SIZE_874 ? false : true}
                  >
                    to partner with the most promising teams in web3, helping them connect with passionate investors to accelerate the growth of the new web3 paradigm.
                  </Text>
                </Box>
              </Flex>
              <Box padding={50} />
            </Flex>
          </Box>
        </Container>
      </ContentWrapper>
      <ContentWrapper backgroundColor={COLORS.BLACK_THEME_BLACK}>
        <Container>
          <Box padding={50}></Box>
          <Box paddingHorizontal={20} paddingVertical={50}>
            <Flex alignItems="center" gap={20} justifyContent="center">
              <Text type="title" lineHeight={1.5} fontWeight={700}>
                The Team
              </Text>
            </Flex>
            <Box padding={30}></Box>
            <Box
              paddingHorizontal={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 50 : 0}
            >
              <Flex
                flexDirection={
                  deviceWidth > WINDOW_SIZES.SIZE_1280 ? "row" : "column"
                }
                alignItems="space-between"
                gap={50}
                justifyContent="center"
              >
                <Box padding={10}>
                  <Flex flexDirection="column" alignItems="center">
                  <Image image={Icons.apollo} width={"150"}/>
                    <Box padding={10}></Box>
                    <Text
                      center
                      type="undertitle"
                      lineHeight={1.5}
                    >
                      Apollo B.
                    </Text>
                    <Text
                      center
                      type="uppersubtitle"
                      lineHeight={1.5}
                      color={COLORS.BLACK_THEME_GREEN}
                    >
                      Head of Comms
                    </Text>
                    <Box padding={10}></Box>
                    <Flex justifyContent="center" alignItems="center" flexDirection="row" gap={15}>
                            <Box
                              borderRadius={5}
                              padding={8}
                              backgroundColor={COLORS.BLACK_THEME_DEEP_GREEN}
                              backgroundImage={COLORS.BLACK_THEME_GRADIENT_GREEN}
                              boxShadow="0px 0px 20px #7E859352"
                              cursor
                              onClick={() => {window.location.replace("https://twitter.com/apollobabade");}}
                            >
                              <Icon
                                icon={Icons.twitterSocial}
                                size="MEDIUM"
                              ></Icon>
                            </Box>
                            <Box
                              borderRadius={5}
                              padding={8}
                              backgroundColor={COLORS.BLACK_THEME_DEEP_GREEN}
                              backgroundImage={COLORS.BLACK_THEME_GRADIENT_GREEN}
                              boxShadow="0px 0px 20px #7E859352"
                              cursor
                              onClick={() => {window.location.replace("https://www.linkedin.com/in/apollo-babade/");}}
                            >
                              <Icon
                                icon={Icons.linkedin}
                                size="MEDIUM"
                              ></Icon>
                            </Box>
                          </Flex>
                    <Box padding={5}></Box>
                  </Flex>
                </Box>
                <Box padding={5}></Box>
                <Box padding={10}>
                  <Flex flexDirection="column" alignItems="center">
                  <Image image={Icons.nick} width={"150"}/>
                    <Box padding={10}></Box>
                    <Text
                      center
                      type="undertitle"
                      lineHeight={1.5}
                      fontWeight={700}
                    >
                      Nick K.
                    </Text>
                    <Text
                      center
                      type="uppersubtitle"
                      lineHeight={1.5}
                      color={COLORS.BLACK_THEME_GREEN}
                    >
                      Managing Partner
                    </Text>
                    <Box padding={10}></Box>
                    <Flex justifyContent="center" alignItems="center" flexDirection="row" gap={15}>
                            <Box
                              borderRadius={5}
                              padding={8}
                              backgroundColor={COLORS.BLACK_THEME_DEEP_GREEN}
                              backgroundImage={COLORS.BLACK_THEME_GRADIENT_GREEN}
                              boxShadow="0px 0px 20px #7E859352"
                              cursor
                              onClick={() => {window.location.replace("https://twitter.com/nickkuznetsov_");}}
                            >
                              <Icon
                                icon={Icons.twitterSocial}
                                size="MEDIUM"
                              ></Icon>
                            </Box>
                            <Box
                              borderRadius={5}
                              padding={8}
                              backgroundColor={COLORS.BLACK_THEME_DEEP_GREEN}
                              backgroundImage={COLORS.BLACK_THEME_GRADIENT_GREEN}
                              boxShadow="0px 0px 20px #7E859352"
                              cursor
                              onClick={() => {window.location.replace("https://www.linkedin.com/in/nickuznetsov");}}
                            >
                              <Icon
                                icon={Icons.linkedin}
                                size="MEDIUM"
                              ></Icon>
                            </Box>
                          </Flex>
                  </Flex>
                </Box>
                <Box padding={5}></Box>
              </Flex>
            </Box>
            <Box padding={50} />
            <Box
              paddingHorizontal={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 100 : 0}
            >
              <Flex
                flexDirection={
                  deviceWidth > WINDOW_SIZES.SIZE_1280 ? "row" : "column"
                }
                alignItems="space-between"
                gap={50}
                justifyContent="center"
              >
                <Box padding={10}>
                  <Flex flexDirection="column" alignItems="center">
                  <Image image={Icons.alana} width={"150"}/>
                    <Box padding={10}></Box>
                    <Text
                      center
                      type="undertitle"
                      lineHeight={1.5}
                      fontWeight={700}
                    >
                      Alana G.
                    </Text>
                    <Text
                      center
                      type="uppersubtitle"
                      lineHeight={1.5}
                      color={COLORS.BLACK_THEME_GREEN}
                    >
                      Head of Branding
                    </Text>
                    <Box padding={10}></Box>
                    <Flex justifyContent="center" alignItems="center" flexDirection="row" gap={15}>
                            <Box
                              borderRadius={5}
                              padding={8}
                              backgroundColor={COLORS.BLACK_THEME_DEEP_GREEN}
                              backgroundImage={COLORS.BLACK_THEME_GRADIENT_GREEN}
                              boxShadow="0px 0px 20px #7E859352"
                              cursor
                              onClick={() => {window.location.replace("https://twitter.com/AlanaGutsaeva");}}
                            >
                              <Icon
                                icon={Icons.twitterSocial}
                                size="MEDIUM"
                              ></Icon>
                            </Box>
                            <Box
                              borderRadius={5}
                              padding={8}
                              backgroundColor={COLORS.BLACK_THEME_DEEP_GREEN}
                              backgroundImage={COLORS.BLACK_THEME_GRADIENT_GREEN}
                              boxShadow="0px 0px 20px #7E859352"
                              cursor
                              onClick={() => {window.location.replace("https://www.linkedin.com/in/alanagutsaeva");}}
                            >
                              <Icon
                                icon={Icons.linkedin}
                                size="MEDIUM"
                              ></Icon>
                            </Box>
                          </Flex>
                  </Flex>
                </Box>
                <Box padding={5}></Box>
                <Box padding={10}>
                  <Flex flexDirection="column" alignItems="center">
                    <Image image={Icons.qichen} width={"150"}/>
                    <Box padding={10}></Box>
                    <Text
                      center
                      type="undertitle"
                      lineHeight={1.5}
                      fontWeight={700}
                    >
                      Qichen H.
                    </Text>
                    <Text
                      center
                      type="uppersubtitle"
                      lineHeight={1.5}
                      color={COLORS.BLACK_THEME_GREEN}
                    >
                      Administration Manager
                    </Text>
                    <Box padding={10}></Box>
                    <Flex justifyContent="center" alignItems="center" flexDirection="row" gap={15}>
                            <Box
                              borderRadius={5}
                              padding={8}
                              backgroundColor={COLORS.BLACK_THEME_DEEP_GREEN}
                              backgroundImage={COLORS.BLACK_THEME_GRADIENT_GREEN}
                              boxShadow="0px 0px 20px #7E859352"
                              cursor
                              onClick={() => {window.location.replace("https://twitter.com/qichen_hu?s=21&t=O4eLEnyp598HUS2bC04I6w");}}
                            >
                              <Icon
                                icon={Icons.twitterSocial}
                                size="MEDIUM"
                              ></Icon>
                            </Box>
                            <Box
                              borderRadius={5}
                              padding={8}
                              backgroundColor={COLORS.BLACK_THEME_DEEP_GREEN}
                              backgroundImage={COLORS.BLACK_THEME_GRADIENT_GREEN}
                              boxShadow="0px 0px 20px #7E859352"
                              cursor
                              onClick={() => {window.location.replace("https://www.linkedin.com/in/qichen-hu-9405b523a");}}
                            >
                              <Icon
                                icon={Icons.linkedin}
                                size="MEDIUM"
                              ></Icon>
                            </Box>
                          </Flex>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Box>
          <Box padding={80} />
        </Container>
      </ContentWrapper>
      <ContentWrapper backgroundColor={COLORS.BLACK_THEME_BLACK}>
        <Container>
          <Box padding={50}></Box>
          <Box paddingHorizontal={20} paddingVertical={50}>
            <Flex alignItems="center" gap={20} justifyContent="center">
              <Text type="title" lineHeight={1.5} fontWeight={700}>
                Our Values
              </Text>
            </Flex>
            <Box padding={30}></Box>
            <Box
              paddingHorizontal={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 50 : 0}
            >
              <Flex
                flexDirection={
                  deviceWidth > WINDOW_SIZES.SIZE_1280 ? "row" : "column"
                }
                alignItems="space-between"
                gap={30}
                justifyContent="center"
              >
                <Box padding={10}>
                  <Flex flexDirection="column" alignItems="center">
                    <Icon icon={Icons.emerald} size="EXTRA_SUPER_LARGE" />
                    <Box padding={10}></Box>
                    <Text
                      center
                      type="undertitle"
                      lineHeight={1.5}
                      fontWeight={700}
                    >
                      High Standards
                    </Text>
                    <Box padding={10}></Box>
                    <Text
                      center
                      justify
                      type="uppersubtitle"
                      lineHeight={1.5}
                      fontWeight={600}
                      color={COLORS.BLACK_THEME_WHITE_GREEN}
                    >
                      We believe in always maintaining the highest standards in all that we do.
                    </Text>
                  </Flex>
                </Box>
                <Box padding={5}></Box>
                <Box padding={10}>
                  <Flex flexDirection="column" alignItems="center">
                    <Icon icon={Icons.bot} size="EXTRA_SUPER_LARGE" />
                    <Box padding={10}></Box>
                    <Text
                      center
                      justify
                      type="undertitle"
                      lineHeight={1.5}
                      fontWeight={700}
                    >
                      Systems Thinking
                    </Text>
                    <Box padding={10}></Box>
                    <Text
                      center
                      justify
                      type="uppersubtitle"
                      lineHeight={1.5}
                      fontWeight={600}
                      color={COLORS.BLACK_THEME_WHITE_GREEN}
                    >
                      We treat everything as a system and always optimize for efficiency.
                    </Text>
                  </Flex>
                </Box>
                <Box padding={5}></Box>
                <Box padding={10}>
                  <Flex flexDirection="column" alignItems="center">
                    <Icon icon={Icons.target} size="EXTRA_SUPER_LARGE" />
                    <Box padding={10}></Box>
                    <Text
                      center
                      type="undertitle"
                      lineHeight={1.5}
                      fontWeight={700}
                    >
                      Keep it Simple
                    </Text>
                    <Box padding={10}></Box>
                    <Text
                      center
                      justify
                      type="uppersubtitle"
                      lineHeight={1.5}
                      fontWeight={600}
                      color={COLORS.BLACK_THEME_WHITE_GREEN}
                    >
                      We believe anything that can be simplified, should be simplified.
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
            <Box padding={30} />
            <Box
              paddingHorizontal={deviceWidth > WINDOW_SIZES.SIZE_1280 ? 100 : 0}
            >
              <Flex
                flexDirection={
                  deviceWidth > WINDOW_SIZES.SIZE_1280 ? "row" : "column"
                }
                alignItems="space-between"
                gap={10}
                justifyContent="center"
              >
                <Box padding={10}>
                  <Flex flexDirection="column" alignItems="center">
                    <Icon icon={Icons.help} size="EXTRA_SUPER_LARGE" />
                    <Box padding={10}></Box>
                    <Text
                      center
                      type="undertitle"
                      lineHeight={1.5}
                      fontWeight={700}
                    >
                      Win By Helping <br/> Others Win
                    </Text>
                    <Box padding={10}></Box>
                    <Text
                      center
                      justify
                      type="uppersubtitle"
                      lineHeight={1.5}
                      fontWeight={600}
                      color={COLORS.BLACK_THEME_WHITE_GREEN}
                    >
                      We know that the easiest way to win is by <br/> constantly solving problems for others.
                    </Text>
                  </Flex>
                </Box>
                <Box padding={5}></Box>
                <Box padding={10}>
                  <Flex flexDirection="column" alignItems="center">
                    <Icon icon={Icons.tire} size="EXTRA_SUPER_LARGE" />
                    <Box padding={10}></Box>
                    <Text
                      center
                      type="undertitle"
                      lineHeight={1.5}
                      fontWeight={700}
                    >
                      Don't Reinvent <br/> The Wheel
                    </Text>
                    <Box padding={10}></Box>
                    <Text
                      center
                      justify
                      type="uppersubtitle"
                      lineHeight={1.5}
                      fontWeight={600}
                      color={COLORS.BLACK_THEME_WHITE_GREEN}
                    >
                      We believe in using proven methods and existing <br/> solutions and building on top of them.
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Box>
          <Box padding={80} />
        </Container>
      </ContentWrapper>
      <ContentWrapper backgroundColor={COLORS.BLACK_THEME_BLACK}>
        <Container>
          <Box padding={30}></Box>
          <Box paddingHorizontal={20} paddingVertical={100}>
            <Flex flexDirection="column" alignItems="center" gap={20}>
              <Text center type="sublogo" fontWeight={600}>
                Looking To Raise <br /> Capital In Web3?
              </Text>
              <Box padding={20}>
                <Button
                  backgroundColor={COLORS.BLACK_THEME_DEEP_GREEN}
                  backgroundHoverColor={COLORS.BLACK_THEME_DIM_GREEN}
                  shadow={false}
                  onClick={() => {
                    window.location.replace(
                      "https://calendly.com/caduceuscapital/preconsultation"
                    );
                  }}
                >
                  FREE PRECONSULTATION
                </Button>
              </Box>
            </Flex>
          </Box>
        </Container>
      </ContentWrapper>
    </Page>
  );
};

const mapStateToProps = (state: any) => {
  return {
    theme: state.ThemeReducer.theme,
    requestAuditModal: state.RequestAuditModalReducer.auditModalOpened,
  };
};

export default connect(mapStateToProps, {
  changeTheme: changeTheme,
  changeAuditModalOpened: changeAuditModalOpened,
})(Company);
